<template>
    <component :is="sectionComponent" padding-top="10px" padding-bottom="10px">
        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            paysafecard classic<br />
            TerminalID: {{ terminalId }} <br />
            Date: {{ date }} <br />
            Time: {{ time }} <br />
            Trace-Nr.: {{ traceCode }}<br />
            Receipt-Nr.: {{ transactionId }}<br />
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            Product without VAT<br /><br />

            Handelaar LET OP!<br />
            PIN-code = cash, en kan nooit per telefoon worden doorgegeven aan derden.<br />
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            paysafecard {{ value }}<br />
            Pincode: {{ code }} <br />
            Serienummer: {{ serial }} <br />
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            <p>Betalen op het internet</p>
            <ul>
                <li>kies webshop en product</li>
                <li>kies paysafecard als betaalmethode</li>
                <li>Toets de PINcode in - klaar</li>
            </ul>
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            <p>Help: <a target="_blank" href="https://www.paysafecard.com/help">www.paysafecard.com/help</a></p>

            <p>
                Deze kaart mag niet beroepsmatig worden doorverkocht paysafecard is een betalingsmiddel van
                <strong>Paysafe Prepaid Services Limited</strong>
            </p>
            <p>
                De algemene voorwaarden vind u op
                <a target="_blank" href="https://www.paysafecard.com">www.paysafecard.com</a>
            </p>
        </component>
    </component>
</template>

<script>
export default {
    props: ['isEmail', 'value', 'code', 'serial', 'terminalId', 'traceCode', 'date', 'time', 'transactionId'],

    computed: {
        sectionComponent() {
            return this.isEmail ? 'mj-section' : 'div';
        },

        textComponent() {
            return this.isEmail ? 'mj-text' : 'div';
        },

        hrComponent() {
            return this.isEmail ? 'mj-divider' : 'hr';
        },
    },
};
</script>
